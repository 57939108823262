<template slot-scope="scope">
  <r-e-dialog :title="(formPublish.uuid?'修改':'添加')+formPublish.typeName+'账单'" :visible.sync="dialogVisible" show-footer top="5vh"
              width="600px"
              @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
    <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
      <div class="flex">
        <el-form-item label="房源信息">
          <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单类型">
          <el-input v-model="formPublish.typeName" disabled placeholder="请填写账单类型"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单周期">
          <el-date-picker v-model="cycle" type="daterange" @input="newValue" format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          style="width: 100%;" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="金额" prop="amount">
          <!--                <el-input-number  v-if="formPublish.uuid"  style="width: 100%;" v-model="formPublish.amount" :disabled="!getButtonMenu('PUTMONEY')"  controls-position="right"/>-->
          <el-input-number style="width: 100%;" :disabled="disabled" v-model="formPublish.amount" controls-position="right"/>
        </el-form-item>
      </div>
      <!-- 2024/07/29 张晓瑜新增账单备注 -->
      <div class="flex" >
        <div style="width:20px"></div>
        <span style="color:red;margin-top: 8px;margin-right: 8px;">*</span>
        <el-form-item label-width="20" label="账单备注" prop="comment">
          <el-input style="width: 87%;" type="textarea" v-model="comment"  placeholder="请输入内容"/>
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
//添加房租账单
import {addBills, updateBills, getHouseLeaserOtherBillsApi} from "@/api/house-resource";
import {getContractByUuidOrLeasorUuid} from "@/api/contract"
import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
import {buttonCookies} from "@storage/cookies";

export default {
  name: "dialog-add-bill",
  data() {
    return {
      disabled:true,
      dialogVisible: false,
      comment:null,
      formPublish: {
        typeName: null,
        type: null,
        amount: null,
        endDate: new Date(),
        startDate: new Date(),
        leasorUuid: null,
        apartmentUuid: null,
        contractUuid: null,
     
      },
      rules: {
        startDate: [{required: true, message: '请选择开始日期', trigger: 'change'}],
        endDate: [{required: true, message: '请选择结束日期', trigger: 'change'}],
        amount: [{required: true, message: '金额不能为空', trigger: 'blur'}],
        // comment: [{required: true, message: '账单备注不能为空', trigger: 'change'}],
      },
      contractUuid: '',
      cycle: [],
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
    }
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    },
    zUuid: {
      type: String,
      default: null,
    }
  },
  async created() {
    // 获取用户uuid, 房源uuid
    const {leasorUuid, apartmentUuid} = this.currentHouse;
    this.uuid = leasorUuid;
    //2022/03/21 王江毅 保存用户uuid, 房源uuid
    this.formPublish.leasorUuid = leasorUuid;
    this.formPublish.apartmentUuid = this.zUuid || apartmentUuid;
  },
  methods: {
    // //获取按钮权限数组
    getButtonMenu(menu) {
      return JSON.parse(buttonCookies.get()).map(({url}) => url).some(v => v === menu);
    },
    //打开添加房租账单弹框方法
    async openDialog(data) {
      console.log(data)
      const that = this;
      //拿出旧数据
      const oldFormPublish = this.formPublish;
      // data不等于null为修改，否则为添加
      if (data !== null) {
        //拆分数据
        const {uuid, amount, startDate, endDate, type, typeName, contract: {uuid: contractUuid},comment} = data;
        //赋值
        this.formPublish.uuid = uuid;
        //转换金额
        this.formPublish.amount = parseFloat((amount / 100).toFixed(2));
        //转换转单类型
        this.formPublish.type = parseInt(type);
        this.formPublish.typeName = typeName;
        this.cycle = [new Date(startDate), new Date(endDate)];
        this.formPublish.contractUuid = contractUuid;
        this.comment = comment;
      } else {
        // //获取租户uuid，房间uuid
        const {leasorUuid} = this.currentHouse;
        const apartmentUuid = this.zUuid || this.currentHouse.apartmentUuid;
        // const loadingOptions = that.loadingOptions;
        // const loading = that.$loading({...loadingOptions});
        // //调用接口获取合同uuid
        // let res = await getContractByUuidOrLeasorUuid({uuid: apartmentUuid, leasorUuid});
        // if (res) {
        //   //获取合同uuid
        //   const {msg: contractUuid} = res;
        //   // 初始化对象
          this.formPublish = {
            ...oldFormPublish,
            typeName: "房租",
            type: 500504,
            leasorUuid,
            apartmentUuid,

          }
        // } else this.formPublish = {...oldFormPublish};
        this.cycle = [];
        // loading.close();
      }
      this.dialogVisible = true;
    },

    async newGetLeasorUuid(data){
      // const that = this;
      const oldFormPublish = this.formPublish;
      //获取租户uuid，房间uuid
      const {leasorUuid} = this.currentHouse;
      const apartmentUuid = this.zUuid || this.currentHouse.apartmentUuid;
      // const loadingOptions = that.loadingOptions;
      // const loading = that.$loading({...loadingOptions});
      //调用接口获取合同uuid
      let res = await getContractByUuidOrLeasorUuid({uuid: apartmentUuid, leasorUuid,...data});
      if (res) {
        //获取合同uuid
        const {msg: contractUuid} = res;
        // 初始化对象
        this.formPublish.contractUuid = contractUuid
      } else this.formPublish = {...oldFormPublish};
    },
    //添加房租账单弹框确认事件
    handleSubmitPublish() {
      //2022/03/21 王江毅 that=this 通过that调用this的变量和方法 以免发生this指引异常
      let that = this;
      // 表单校验
      that.$refs["formPublish"].validate((valid) => {
        if (valid) {
          let cycle = that.cycle;
          if (!cycle || cycle.length === 0) {
            MessageWarning("账单周期不能为空");
            return;
          }
          if(this.comment === '' ){
            MessageWarning("账单备注不能为空");
            return;
          }
          //获取房租账单uuid
          let {uuid} = that.formPublish;
          //判断uuid存不存在，存在修改，否则添加
          if (uuid) that.updateBills();
          else that.addBills();
        } else {
          MessageWarning("请填写必填值", 1000);
          return false;
        }
      });

    },

    //添加房租账单弹框取消事件
    handleCancel() {
      this.dialogVisible = false;
      this.formPublish = {
        typeName: null, type: null, amount: null, endDate: new Date(), startDate: new Date(), leasorUuid: null,
        apartmentUuid: null,
      }
      //2022/03/21 调用父组件方法 刷新未支付房租账单列表
      this.$emit('clickCancel');
    },

    //添加房租账单接口
    addBills() {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //2022/03/21 王江毅 解构出需要的字段 并赋值,以免双向数据绑定导致展示数据异常
      let {leasorUuid, apartmentUuid, startDate, endDate, amount, contractUuid, type} = that.formPublish;
      console.log(that.formPublish)
      amount = parseInt((amount * 100).toFixed(0));
      //2022/03/21 王江毅 打包数据
      const paramData = {leasorUuid, apartmentUuid, startTime: startDate, endTime: endDate, amount, contractUuid, type,comment:this.comment};
      console.log(paramData)
      //2022/03/21 王江毅 调用接口添加房租账单
      addBills(paramData).then(res => {
        MessageSuccess('添加房租账单成功');
        //调用取消关闭弹框
        that.handleCancel();
      }).finally(() => loading.close());
    },

    //修改房租账单方法
    updateBills() {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //2022/03/21 王江毅 解构出需要的字段 并赋值,以免双向数据绑定导致展示数据异常
      let {startDate, endDate, amount, uuid, contractUuid, type} = that.formPublish;
      console.log('comment',this.comment);
      amount = parseInt((amount * 100).toFixed(0));
      //2022/03/21 王江毅 打包数据
      const paramData = {startTime: startDate, endTime: endDate, amount, uuid, contractUuid, type,comment:this.comment};

      //2022/03/21 王江毅 调用接口添加杂费账单
      updateBills(paramData).then(res => {
        MessageSuccess('修改房租账单成功');
        //调用取消关闭弹框
        that.handleCancel();
      }).finally(() => loading.close());
    },
    // 2024-5-30-新增房租账单计算
   async newValue() {
      if ( this.cycle.length < 2) return
      console.log(this.cycle,'cycle')
    await  this.newGetLeasorUuid({billStartDate: this.cycle[0], billEndDate : this.cycle[1]})
     setTimeout(() => {
       console.log(this.formPublish)
     },4000)
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      let data = {
        uuid: this.formPublish.contractUuid,
        type: '',
        startDate: this.cycle[0],
        endDate: this.cycle[1],
      }
      if (this.formPublish.typeName === '房租') data.type = 500504
      if (this.formPublish.typeName === '押金') data.type = 500512
      if (this.formPublish.typeName === '服务费') data.type = 500507
      console.log(data, this.formPublish)
      getHouseLeaserOtherBillsApi(data).then(res => {
        loading.close()
        this.formPublish.amount = res.info.amount / 100
        this.comment = res.info.comment
      }).catch(err => {
        loading.close()
        console.log(err)
        this.formPublish.amount = 0
        this.cycle = []
        this.formPublish.startDate = null
        this.formPublish.endDate = null
      })
    }
  },
  watch: {
    cycle(value) {
      if (value) {
        let [startDate, endDate] = value;
        this.formPublish.startDate = startDate;
        this.formPublish.endDate = endDate;
        console.log(this.formPublish)
      } else {
        this.formPublish.amount = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}

.flex {
  display: flex;

  /deep/ .el-form-item {
    flex: 1;
    white-space: nowrap;
  }

  /deep/ .amount {
    .el-input {
      width: 42%;
    }
  }

  /deep/ .tag-group {
    white-space: normal;

    .el-tag {
      margin: 0 VW(5px) VH(5px) 0;
      cursor: pointer;
    }
  }

  /deep/ .samplePhotos {
    white-space: normal;
  }
}
</style>
<style>
.v-modal {
  z-index: 2000 !important;
}
</style>
